<template>
  <BaseCardWithHeader
    toolbarTitle="Reductions & Increases"
    toolbarSubTitle="Examine the trend in index variation and impact on claims."
  >
    <v-overlay
      v-if="overlayOpen"
      :absolute="true"
      :value="overlayOpen"
      :opacity="0.7"
      z-index="1"
    >
      <BaseCardWithHeader
        :hasCollectionButton="false"
        cardColor="rgba(47,77,130, 0.9)"
        :toolbarTitle="`Birth Year: ${selectedTableRow.birthYear.toString()}`"
        toolbarSubTitle=""
        alternateButton="mdi-close"
        :alternateButtonFunction="this.toggleOverlay"
      >
        <TableChart :chartData="selectedTableRow" :yearRange="selectedYears" />
      </BaseCardWithHeader>
    </v-overlay>
    <v-data-table
      :headers="headers"
      :items="checkTableData"
      :items-per-page="7"
      :single-select="false"
      @click:row="handleRowClick"
      item-key="birthYear"
      :loading="tableIsLoading"
      loading-text="Loading... Please wait"
      class="transparent  pgi-table-row--clickable"
    >
      <template v-slot:top>
        <v-container class="py-0">
          <v-alert
            v-model="claimsTableAlert"
            dense
            border="left"
            text
            type="warning"
            dismissible
            close-text="Close Alert"
            class="mt-2"
          >
            {{ claimsTableAlertText }}
          </v-alert>
        </v-container>
        <v-container class="mt-2">
          <v-row align="center">
            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="selectedYears"
                :items="yearOptions"
                :menu-props="{ maxHeight: '400' }"
                @blur="onYearsChange($event)"
                @input="selectedYearLimiter"
                label="Select Years"
                multiple
                dense
              ></v-select>
            </v-col>
            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="selectedIndex"
                :items="indexOptions"
                label="Select Index"
                dense
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:item.year1Index="{ item }">
        {{ item.year1Index | toCurrency }}
      </template>

      <template v-slot:item.year2Index="{ item }">
        {{ item.year2Index | toCurrency }}
      </template>

      <template v-slot:item.trend="{ item }">
        <v-icon :color="getTrendColor(item.trend)" medium dark>
          {{ getTrendIcon(item.trend) }}
        </v-icon>
      </template>
    </v-data-table>

    <!-- <v-snackbar
      v-model="claimsTableSnackbar"
      icon="warning"
      color="warning"
      :top="true"
      :multi-line="false"
    >
      {{ claimsTableSnackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="claimsTableSnackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar> -->
  </BaseCardWithHeader>
</template>

<script>
import config from "@/config";
import { mapActions, mapGetters } from "vuex";
import BaseCardWithHeader from "@/components/BaseCardWithHeader";
import TableChart from "@/components/TableChart";

export default {
  components: {
    BaseCardWithHeader,
    TableChart,
  },
  data() {
    return {
      yearRange: this.getAllYearsAsArray,
      yearOptions: [
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
      ],
      indexOptions: ["Annuity"],
      selectedYears: [],
      selectedIndex: "Annuity",
      selected: [],
      selectedTableRow: null,
      overlayOpen: false,
      headers: [
        {
          text: "Birth Year",
          align: "start",
          sortable: true,
          value: "birthYear",
        },
        {
          text: "Year 1",
          sortable: true,
          value: "year1Index",
        },
        {
          text: "Year 2",
          sortable: true,
          value: "year2Index",
        },
        { text: "Change (%)", sortable: true, value: "change" },
        { text: "Trending", sortable: true, value: "trend" },
      ],
      tableData: [],
      tableIsLoading: false,
      claimsTableAlert: false,
      claimsTableAlertText: "",
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapGetters("app", ["initialClaimsTableData"]),
    checkTableData() {
      return this.tableData;
    },
  },
  mounted() {
    this.selectedYears = [
      String(this.currentYear - 1),
      String(this.currentYear),
    ];
    this.yearRange = this.getAllYearsAsArray();

    this.getTableData();
  },
  methods: {
    ...mapActions("app", ["setInitialClaimsTableData"]),
    getAllYearsAsArray() {
      let newRange = [];
      if (this.currentYear) {
        const yearPrior = this.currentYear - 18;
        const range = (start, stop, step) =>
          Array.from(
            { length: (stop - start) / step + 1 },
            (_, i) => start + i * step
          );
        newRange = range(1930, yearPrior, 1);
      }
      return newRange;
    },
    toggleOverlay() {
      this.overlayOpen = !this.overlayOpen;
    },
    selectedYearLimiter(e) {
      // Only allow two years to be selected
      if (e.length > 2) {
        // console.log(" you can only select two", e);
        // Remove first year in array as others are selected after it
        e.shift();
      }
    },
    onYearsChange() {
      const years = this.selectedYears;
      this.selectedYears = years.slice(0, 2).sort();

      if (years.length === 2) {
        this.claimsTableAlert = false;
        this.getTableData();
      } else {
        this.claimsTableAlertText = "Please select two years to compare.";
        this.claimsTableAlert = true;
        this.selectedYears = []; //["2020", "2021"];
        return;
      }
    },
    handleRowClick(rowData) {
      this.selectedTableRow = rowData;
      this.overlayOpen = true;
    },
    getTrendColor(trend) {
      if (trend > 0) return "green";
      else if (trend < 0) return "red";
      else return "grey";
    },
    getTrendIcon(trend) {
      if (trend > 0) return "mdi-trending-up";
      else if (trend < 0) return "mdi-trending-down";
      else return "mdi-trending-neutral";
    },
    async getTableData() {
      this.tableIsLoading = true;
      this.tableData = [];

      if (
        this.initialClaimsTableData &&
        this.initialClaimsTableData.length > 0 &&
        this.selectedYears.includes(String(this.currentYear - 1)) &&
        this.selectedYears.includes(String(this.currentYear))
      ) {
        setTimeout(() => {
          this.tableData = Object.assign(this.initialClaimsTableData);
        }, 1500);
      } else {
        const yearsToCompare = this.selectedYears.sort();
        const year1 = await this.getBirthYearData(yearsToCompare[0]);
        const year2 = await this.getBirthYearData(yearsToCompare[1]);
        let newData = [];

        year1.map(function(item, idx) {
          let dataObj = {
            birthYear: item.birthYear,
            change: null,
            trend: 0,
          };

          var percentchange =
            ((year2[idx].indexValue - item.indexValue) / item.indexValue) *
            100.0;

          dataObj.year1Index = item.indexValue;
          dataObj.year2Index = year2[idx].indexValue;

          dataObj.change = percentchange.toFixed(4);

          if (dataObj.change < 0) {
            dataObj.trend = -1;
          } else if (dataObj.change > 0) {
            dataObj.trend = 1;
          } else {
            dataObj.trend = 0;
          }

          newData.push(dataObj);
        });

        this.tableData = Object.assign(newData);

        this.setInitialClaimsTableData(this.tableData);
      }

      this.headers[1].text = String(this.selectedYears[0]);
      this.headers[2].text = String(this.selectedYears[1]);

      if (this.initialClaimsTableData) {
        setTimeout(() => {
          this.tableIsLoading = false;
        }, 2000);
      } else {
        this.tableIsLoading = false;
      }
    },
    async getBirthYearData(selectedYear) {
      let newData = [];
      // eslint-disable-next-line
      for (const [idx, year] of this.yearRange.entries()) {
        const currYear = this.currentYear;
        const fetchPath = `${config.SSBI_API_URL}get_summary?yob=${year}&data_year=${currYear}`;

        try {
          await fetch(fetchPath)
            .then((r) => r.json())
            .then(
              (json) => {
                let dataObj = {
                  birthYear: year,
                  indexValue: null,
                  trend: null,
                };
                // For year summary data coming in {year}, find the index for the year given (eg: 2019)
                // add that to an array for data
                Object.entries(json.data).map(([k, v]) => {
                  // console.log([k, v]);
                  if ([k, v][1].year === parseInt(selectedYear)) {
                    dataObj.indexValue = [k, v][1].index;
                    newData.push(dataObj);
                  }
                });
              },
              (response) => {
                console.log("Error loading json:", response);
              }
            );
        } catch (error) {
          console.log("error fetching date year", error);
        }

        // }
      }

      // console.log("THE NEW DATA", newData);
      return newData;
    },
    formatCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      });
      return formatter.format(value);
    },
    saveFile(arr) {
      const data = JSON.stringify(arr);
      const blob = new Blob([data], { type: "text/plain" });
      const e = document.createEvent("MouseEvents"),
        a = document.createElement("a");
      a.download = "test.json";
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ["text/json", a.download, a.href].join(":");
      e.initEvent(
        "click",
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      a.dispatchEvent(e);
    },
  },
};
</script>

<style lang="scss">
.theme--dark.v-data-table tbody tr.v-data-table__selected,
.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: rgba(white, 0.03) !important;
}
</style>
<style lang="scss">
.pgi-table-row {
  &--clickable {
    tr {
      cursor: pointer;
    }
  }
}
</style>
