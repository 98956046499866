<template>
  <v-img
    height="145px"
    src="@/assets/images/blackhole.jpg"
    gradient="to top right, rgb(18 95 41), rgb(8 102 144 / 91%)"
  >
    <BaseCardWithHeader
      toolbarTitle="Download Reports"
      toolbarSubTitle="Download and Customize Reports"
      cardColor="transparent"
      alternateButton="mdi-folder-plus-outline"
      :hasCollectionButton="false"
      :flat="true"
    >
      <div class="pa-4 d-flex align-center">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              width="100%"
              outlined
              class="justify-space-between"
            >
              Select Report
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="item in reportItems"
              :key="item.id"
              @click.stop="handleViewDownloadReport(item)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <v-list-item-action>
                <v-icon>
                  {{ item.icon }}
                </v-icon>
              </v-list-item-action>
              <v-dialog
                v-if="item.content && item.id === currentOpenItem"
                v-model="isDownloadReportDialogOpen"
                overlay-opacity="0.8"
                overlay-color="#2C4779"
                :scrollable="true"
                max-width="1200"
                @click:outside="currentOpenItem = null"
              >
                <v-card light>
                  <v-card-title class="pa-0">
                    <v-toolbar color="dashCard" dark>
                      <v-toolbar-title>{{ item.title }}</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn
                        icon
                        @click="
                          (currentOpenItem = null),
                            (isDownloadReportDialogOpen = false)
                        "
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar></v-card-title
                  >
                  <v-card-text v-html="item.content" class="pt-8">
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </BaseCardWithHeader>
  </v-img>
</template>

<script>
import BaseCardWithHeader from "@/components/BaseCardWithHeader";

export default {
  components: {
    BaseCardWithHeader,
  },
  data() {
    return {
      isDownloadReportDialogOpen: false,
      currentOpenItem: null,
      reportItems: [
        {
          id: 2022,
          title: "Social Security Annual Report Summary: 2022",
          icon: "mdi-open-in-app",
          dialog: true,
          type: "dialog",
          content: require("@/assets/reports/2022_report_summary.html"),
        },
        {
          id: 111,
          title: "Social Security Annual Report Summary: 2021",
          icon: "mdi-open-in-app",
          dialog: true,
          type: "dialog",
          content: require("@/assets/reports/2021_report_summary.html"),
        },
        {
          id: 222,
          title: "Social Security Annual Report Summary: 2020",
          icon: "mdi-open-in-app",
          dialog: true,
          type: "dialog",
          content: require("@/assets/reports/2020_report_summary.html"),
        },
        {
          id: 333,
          title: "SSBI Year-Over-Year Change",
          icon: "mdi-download-outline",
          type: "download",
          fileName: "PlanGapInsights-SSBI-2020-2021-yoy-trend.xlsx",
          content: require("@/assets/reports/PlanGapInsights-SSBI-2020-2021-yoy-trend.xlsx")
            .default,
        },
        {
          id: 444,
          title: "State Level Data",
          icon: "mdi-download-outline",
          type: "download",
          fileName: "State_Level_Data_PlanGap_Insights.xlsx",
          content: require("@/assets/reports/State_Level_Data_PlanGap_Insights.xlsx")
            .default,
        },
        {
          id: 555,
          title: "Advisory - TRUST Act Executive Summary",
          icon: "mdi-download-outline",
          type: "download",
          fileName: "PlanGap_Insights_-_TRUST_Act_Executive_Summary.pdf",
          content: require("@/assets/reports/PlanGap_Insights_-_TRUST_Act_Executive_Summary.pdf")
            .default,
        },
        {
          id: 667,
          title: "Advisory - 2021 OASI Trust Fund Summary",
          icon: "mdi-download-outline",
          type: "download",
          fileName: "PlanGap_Insights_-_2021_OASI_Trust_Fund_Summary.pdf",
          content: require("@/assets/reports/PlanGap_Insights_-_2021_OASI_Trust_Fund_Summary.pdf")
            .default,
        },
      ],
    };
  },
  methods: {
    handleViewDownloadReport(item) {
      if (item.type === "dialog") {
        this.currentOpenItem = item.id;
        this.isDownloadReportDialogOpen = true;
      } else {
        let link = document.createElement("a");
        link.href = item.content;
        link.download = item.fileName;
        link._target = "blank";
        link.click();

        // this.$http
        //   .get("/resources/" + fileName, { responseType: "arraybuffer" })
        //   .then(res => {
        //     let blob = new Blob([res.data], { type: "application/*" });
        //     let link = document.createElement("a");
        //     link.href = window.URL.createObjectURL(blob);
        //     link.download = fileName;
        //     link._target = "blank";
        //     link.click();
        //   });
      }
    },
  },
};
</script>

<style></style>
