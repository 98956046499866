<template>
  <highcharts
    :constructor-type="'mapChart'"
    class="hc"
    :options="mapOptions"
    ref="map"
  ></highcharts>
</template>

<script>
import usaAllMap from "@highcharts/map-collection/countries/us/custom/us-all-territories.geo.json";
const data = [
  [
    "us-al",
    4889347,
    {
      total_population: 4889347,
      population_41_50: 598270,
      population_51_60: 648334,
      population_61_70: 581750,
      population_71_80: 355223,
      population_receiving_ss: 762144,
      avg_monthly_benefit: "$1,498.98"
    }
  ],
  [
    "us-ak",
    712114,
    {
      total_population: 712114,
      population_41_50: 83600,
      population_51_60: 91803,
      population_61_70: 77982,
      population_71_80: 36110,
      population_receiving_ss: 78559,
      avg_monthly_benefit: "$1,462.96"
    }
  ],
  [
    "us-az",
    7259090,
    {
      total_population: 7259090,
      population_41_50: 858257,
      population_51_60: 867841,
      population_61_70: 820990,
      population_71_80: 567509,
      population_receiving_ss: 1091788,
      avg_monthly_benefit: "$1,557.74"
    }
  ],
  [
    "us-ar",
    3012542,
    {
      total_population: 3012542,
      population_41_50: 358707,
      population_51_60: 380389,
      population_61_70: 344691,
      population_71_80: 220465,
      population_receiving_ss: 466687,
      avg_monthly_benefit: "$1,448.48"
    }
  ],
  [
    "us-ca",
    39356141,
    {
      total_population: 39356141,
      population_41_50: 4999962,
      population_51_60: 4942826,
      population_61_70: 4026780,
      population_71_80: 2338360,
      population_receiving_ss: 4748725,
      avg_monthly_benefit: "$1,496.13"
    }
  ],
  [
    "us-co",
    5722795,
    {
      total_population: 5722795,
      population_41_50: 728577,
      population_51_60: 705791,
      population_61_70: 624066,
      population_71_80: 337100,
      population_receiving_ss: 702606,
      avg_monthly_benefit: "$1,536.82"
    }
  ],
  [
    "us-ct",
    3558382,
    {
      total_population: 3558382,
      population_41_50: 438538,
      population_51_60: 522336,
      population_61_70: 423018,
      population_71_80: 252772,
      population_receiving_ss: 535573,
      avg_monthly_benefit: "$1,683.52"
    }
  ],
  [
    "us-de",
    970344,
    {
      total_population: 970344,
      population_41_50: 110517,
      population_51_60: 133347,
      population_61_70: 127561,
      population_71_80: 79724,
      population_receiving_ss: 169028,
      avg_monthly_benefit: "$1,654.54"
    }
  ],
  [
    "us-dc",
    701570,
    {
      total_population: 701570,
      population_41_50: 79521,
      population_51_60: 71852,
      population_61_70: 59698,
      population_71_80: 34253,
      population_receiving_ss: 61023,
      avg_monthly_benefit: "$1,476.07"
    }
  ],
  [
    "us-fl",
    21409839,
    {
      total_population: 21409839,
      population_41_50: 2610054,
      population_51_60: 2877386,
      population_61_70: 2666197,
      population_71_80: 1927118,
      population_receiving_ss: 3676644,
      avg_monthly_benefit: "$1,516.23"
    }
  ],
  [
    "us-ga",
    10554409,
    {
      total_population: 10554409,
      population_41_50: 1381485,
      population_51_60: 1368438,
      population_61_70: 1091180,
      population_71_80: 635655,
      population_receiving_ss: 1339030,
      avg_monthly_benefit: "$1,500.60"
    }
  ],
  [
    "us-hi",
    1370589,
    {
      total_population: 1370589,
      population_41_50: 163956,
      population_51_60: 174129,
      population_61_70: 169737,
      population_71_80: 105726,
      population_receiving_ss: 227881,
      avg_monthly_benefit: "$1,516.20"
    }
  ],
  [
    "us-id",
    1783529,
    {
      total_population: 1783529,
      population_41_50: 208834,
      population_51_60: 209858,
      population_61_70: 202566,
      population_71_80: 121241,
      population_receiving_ss: 272240,
      avg_monthly_benefit: "$1,493.37"
    }
  ],
  [
    "us-il",
    12644109,
    {
      total_population: 12644109,
      population_41_50: 1582584,
      population_51_60: 1662316,
      population_61_70: 1414699,
      population_71_80: 812286,
      population_receiving_ss: 1711535,
      avg_monthly_benefit: "$1,557.59"
    }
  ],
  [
    "us-in",
    6729379,
    {
      total_population: 6729379,
      population_41_50: 813470,
      population_51_60: 865027,
      population_61_70: 760813,
      population_71_80: 435845,
      population_receiving_ss: 987047,
      avg_monthly_benefit: "$1,589.86"
    }
  ],
  [
    "us-ia",
    3153269,
    {
      total_population: 3153269,
      population_41_50: 358545,
      population_51_60: 400370,
      population_61_70: 370478,
      population_71_80: 214116,
      population_receiving_ss: 502943,
      avg_monthly_benefit: "$1,533.06"
    }
  ],
  [
    "us-ks",
    2890576,
    {
      total_population: 2890576,
      population_41_50: 328861,
      population_51_60: 356988,
      population_61_70: 326378,
      population_71_80: 185226,
      population_receiving_ss: 420788,
      avg_monthly_benefit: "$1,579.30"
    }
  ],
  [
    "us-ky",
    4450887,
    {
      total_population: 4450887,
      population_41_50: 549682,
      population_51_60: 593862,
      population_61_70: 525588,
      population_71_80: 309075,
      population_receiving_ss: 650529,
      avg_monthly_benefit: "$1,455.24"
    }
  ],
  [
    "us-la",
    4632866,
    {
      total_population: 4632866,
      population_41_50: 538808,
      population_51_60: 593720,
      population_61_70: 527713,
      population_71_80: 301054,
      population_receiving_ss: 601270,
      avg_monthly_benefit: "$1,423.08"
    }
  ],
  [
    "us-me",
    1342845,
    {
      total_population: 1342845,
      population_41_50: 160198,
      population_51_60: 201191,
      population_61_70: 195039,
      population_71_80: 115717,
      population_receiving_ss: 253103,
      avg_monthly_benefit: "$1,432.06"
    }
  ],
  [
    "us-md",
    6010629,
    {
      total_population: 6010629,
      population_41_50: 753746,
      population_51_60: 842520,
      population_61_70: 672662,
      population_71_80: 388952,
      population_receiving_ss: 775729,
      avg_monthly_benefit: "$1,617.70"
    }
  ],
  [
    "us-ma",
    6887571,
    {
      total_population: 6887571,
      population_41_50: 838799,
      population_51_60: 959660,
      population_61_70: 798522,
      population_71_80: 465021,
      population_receiving_ss: 946276,
      avg_monthly_benefit: "$1,575.70"
    }
  ],
  [
    "us-mi",
    9983148,
    {
      total_population: 9983148,
      population_41_50: 1188210,
      population_51_60: 1364990,
      population_61_70: 1237898,
      population_71_80: 710569,
      population_receiving_ss: 1611729,
      avg_monthly_benefit: "$1,620.13"
    }
  ],
  [
    "us-mn",
    5637494,
    {
      total_population: 5637494,
      population_41_50: 661355,
      population_51_60: 743797,
      population_61_70: 644448,
      population_71_80: 359075,
      population_receiving_ss: 821317,
      avg_monthly_benefit: "$1,589.07"
    }
  ],
  [
    "us-ms",
    2962093,
    {
      total_population: 2962093,
      population_41_50: 356705,
      population_51_60: 380382,
      population_61_70: 339162,
      population_71_80: 200766,
      population_receiving_ss: 444955,
      avg_monthly_benefit: "$1,430.12"
    }
  ],
  [
    "us-mo",
    6118557,
    {
      total_population: 6118557,
      population_41_50: 716927,
      population_51_60: 806675,
      population_61_70: 721134,
      population_71_80: 431479,
      population_receiving_ss: 933357,
      avg_monthly_benefit: "$1,502.92"
    }
  ],
  [
    "us-mt",
    1065217,
    {
      total_population: 1065217,
      population_41_50: 118196,
      population_51_60: 135837,
      population_61_70: 144785,
      population_71_80: 83289,
      population_receiving_ss: 185800,
      avg_monthly_benefit: "$1,448.33"
    }
  ],
  [
    "us-ne",
    1927803,
    {
      total_population: 1927803,
      population_41_50: 218749,
      population_51_60: 233829,
      population_61_70: 213598,
      population_71_80: 120920,
      population_receiving_ss: 269565,
      avg_monthly_benefit: "$1,533.62"
    }
  ],
  [
    "us-nv",
    3069329,
    {
      total_population: 3069329,
      population_41_50: 394382,
      population_51_60: 389574,
      population_61_70: 342789,
      population_71_80: 215338,
      population_receiving_ss: 429093,
      avg_monthly_benefit: "$1,493.00"
    }
  ],
  [
    "us-nh",
    1358406,
    {
      total_population: 1358406,
      population_41_50: 164463,
      population_51_60: 210551,
      population_61_70: 182407,
      population_71_80: 102394,
      population_receiving_ss: 230371,
      avg_monthly_benefit: "$1,635.82"
    }
  ],
  [
    "us-nj",
    8872796,
    {
      total_population: 8872796,
      population_41_50: 1149687,
      population_51_60: 1260466,
      population_61_70: 1000410,
      population_71_80: 593202,
      population_receiving_ss: 1270091,
      avg_monthly_benefit: "$1,688.66"
    }
  ],
  [
    "us-nm",
    2085174,
    {
      total_population: 2085174,
      population_41_50: 234707,
      population_51_60: 259336,
      population_61_70: 254148,
      population_71_80: 156567,
      population_receiving_ss: 324741,
      avg_monthly_benefit: "$1,439.43"
    }
  ],
  [
    "us-ny",
    19428617,
    {
      total_population: 19428617,
      population_41_50: 2365229,
      population_51_60: 2634444,
      population_61_70: 2204735,
      population_71_80: 1311235,
      population_receiving_ss: 2750721,
      avg_monthly_benefit: "$1,581.82"
    }
  ],
  [
    "us-nc",
    10390610,
    {
      total_population: 10390610,
      population_41_50: 1331387,
      population_51_60: 1376904,
      population_61_70: 1201719,
      population_71_80: 730795,
      population_receiving_ss: 1577166,
      avg_monthly_benefit: "$1,527.50"
    }
  ],
  [
    "us-nd",
    754887,
    {
      total_population: 754887,
      population_41_50: 78842,
      population_51_60: 88376,
      population_61_70: 82058,
      population_71_80: 44621,
      population_receiving_ss: 104538,
      avg_monthly_benefit: "$1,466.45"
    }
  ],
  [
    "us-oh",
    11680341,
    {
      total_population: 11680341,
      population_41_50: 1392063,
      population_51_60: 1559692,
      population_61_70: 1419128,
      population_71_80: 816279,
      population_receiving_ss: 1711710,
      avg_monthly_benefit: "$1,502.82"
    }
  ],
  [
    "us-ok",
    3937529,
    {
      total_population: 3937529,
      population_41_50: 458468,
      population_51_60: 480504,
      population_61_70: 431509,
      population_71_80: 262326,
      population_receiving_ss: 562175,
      avg_monthly_benefit: "$1,488.19"
    }
  ],
  [
    "us-or",
    4215178,
    {
      total_population: 4215178,
      population_41_50: 528286,
      population_51_60: 516495,
      population_61_70: 523835,
      population_71_80: 314170,
      population_receiving_ss: 694130,
      avg_monthly_benefit: "$1,529.75"
    }
  ],
  [
    "us-pa",
    12795687,
    {
      total_population: 12795687,
      population_41_50: 1507094,
      population_51_60: 1761223,
      population_61_70: 1601102,
      population_71_80: 949797,
      population_receiving_ss: 2110876,
      avg_monthly_benefit: "$1,582.80"
    }
  ],
  [
    "us-ri",
    1054930,
    {
      total_population: 1054930,
      population_41_50: 123624,
      population_51_60: 149046,
      population_61_70: 127929,
      population_71_80: 73163,
      population_receiving_ss: 166449,
      avg_monthly_benefit: "$1,559.68"
    }
  ],
  [
    "us-sc",
    5107226,
    {
      total_population: 5107226,
      population_41_50: 618210,
      population_51_60: 676308,
      population_61_70: 638182,
      population_71_80: 401213,
      population_receiving_ss: 857271,
      avg_monthly_benefit: "$1,541.59"
    }
  ],
  [
    "us-sd",
    881196,
    {
      total_population: 881196,
      population_41_50: 94266,
      population_51_60: 109366,
      population_61_70: 107735,
      population_71_80: 56796,
      population_receiving_ss: 143205,
      avg_monthly_benefit: "$1,458.26"
    }
  ],
  [
    "us-tn",
    6808577,
    {
      total_population: 6808577,
      population_41_50: 850573,
      population_51_60: 895043,
      population_61_70: 788032,
      population_71_80: 479597,
      population_receiving_ss: 1037121,
      avg_monthly_benefit: "$1,514.56"
    }
  ],
  [
    "us-tx",
    28881232,
    {
      total_population: 28881232,
      population_41_50: 3687270,
      population_51_60: 3395670,
      population_61_70: 2709760,
      population_71_80: 1520535,
      population_receiving_ss: 3160642,
      avg_monthly_benefit: "$1,495.09"
    }
  ],
  [
    "us-ut",
    3201120,
    {
      total_population: 3201120,
      population_41_50: 375516,
      population_51_60: 305123,
      population_61_70: 263154,
      population_71_80: 149057,
      population_receiving_ss: 317729,
      avg_monthly_benefit: "$1,567.49"
    }
  ],
  [
    "us-vt",
    623417,
    {
      total_population: 623417,
      population_41_50: 72348,
      population_51_60: 90000,
      population_61_70: 87715,
      population_71_80: 50706,
      population_receiving_ss: 114519,
      avg_monthly_benefit: "$1,547.58"
    }
  ],
  [
    "us-va",
    8420244,
    {
      total_population: 8420244,
      population_41_50: 1063248,
      population_51_60: 1136342,
      population_61_70: 940022,
      population_71_80: 565193,
      population_receiving_ss: 1165691,
      avg_monthly_benefit: "$1,574.00"
    }
  ],
  [
    "us-wa",
    7562699,
    {
      total_population: 7562699,
      population_41_50: 936632,
      population_51_60: 944755,
      population_61_70: 860660,
      population_71_80: 420296,
      population_receiving_ss: 1060555,
      avg_monthly_benefit: "$1,604.23"
    }
  ],
  [
    "us-wv",
    1791176,
    {
      total_population: 1791176,
      population_41_50: 222274,
      population_51_60: 243420,
      population_61_70: 245596,
      population_71_80: 151293,
      population_receiving_ss: 314372,
      avg_monthly_benefit: "$1,500.81"
    }
  ],
  [
    "us-wi",
    5819432,
    {
      total_population: 5819432,
      population_41_50: 682484,
      population_51_60: 806238,
      population_61_70: 715084,
      population_71_80: 399619,
      population_receiving_ss: 958900,
      avg_monthly_benefit: "$1,568.81"
    }
  ],
  [
    "us-wy",
    575665,
    {
      total_population: 575665,
      population_41_50: 65616,
      population_51_60: 71416,
      population_61_70: 73139,
      population_71_80: 39395,
      population_receiving_ss: 88778,
      avg_monthly_benefit: "$1,551.20"
    }
  ]
];

// data = data.forEach(function(p) {
//   p[0] = p[0].toUpperCase();
// });
export default {
  data() {
    const that = this;
    return {
      mapOptions: {
        chart: {
          map: usaAllMap,
          backgroundColor: "transparent",
          height: 800
        },
        legend: {
          enabled: false
        },
        credits: false,
        title: false,
        subtitle: false,
        mapNavigation: {
          enabled: false,
          buttonOptions: {
            verticalAlign: "bottom"
          }
        },
        colorAxis: {
          min: 0
        },
        series: [
          {
            data: data,
            name: "Click for Information",
            states: {
              hover: {
                color: "#50AE55"
              }
            },
            dataLabels: {
              enabled: false,
              format: "{point.name}"
            }
          },
          {
            name: "Separators",
            type: "mapline",
            // data: Highcharts.geojson(
            //   Highcharts.maps["countries/us/custom/us-all-territories"],
            //   "mapline"
            // ),
            //data: data,
            color: "silver",
            nullColor: "silver",
            showInLegend: false,
            enableMouseTracking: false
          }
        ],
        tooltip: {
          formatter: function() {
            // console.log("point", this.point);

            return `<strong>${this.point.name}</strong><hr>${that.getMapData(
              this.point.value
            )}`;
          },
          useHTML: true
        }
        // plotOptions: {
        //   series: {
        //     point: {
        //       events: {
        //         click: function() {
        //           var text =
        //               "<b>Clicked point</b><br>Series: " +
        //               this.series.name +
        //               "<br>Point: " +
        //               this.name +
        //               " (" +
        //               this.value +
        //               "/km²)",
        //             chart = this.series.chart;
        //           if (!chart.clickLabel) {
        //             chart.clickLabel = chart.renderer
        //               .label(text, 0, 250)
        //               .css({
        //                 width: "180px"
        //               })
        //               .add();
        //           } else {
        //             chart.clickLabel.attr({
        //               text: text
        //             });
        //           }
        //         }
        //       }
        //     }
        //   }
        // }

        // series: [
        //   {
        //     animation: {
        //       duration: 1000
        //     },
        //     data: data,
        //     joinBy: ["postal-code"],
        //     dataLabels: {
        //       enabled: true,
        //       color: "#FFFFFF",
        //       format: "{point.code}"
        //     },
        //     name: "States",
        //     tooltip: {
        //       pointFormat: "{point.code}"
        //     }
        //   }
        // ]
        // series: [
        //   {
        //     data: data,
        //     name: "States",
        //     states: {
        //       hover: {
        //         color: "#a4edba",
        //         borderColor: "gray"
        //       }
        //     },
        //     joinBy: ["postal-code", "code"],
        //     dataLabels: {
        //       enabled: true,
        //       color: "#FFFFFF",
        //       format: "{point.code}"
        //     },

        //     tooltip: {
        //       headerFormat: "",
        //       pointFormat: "{point.name}"
        //     }
        //   }
        // ]
      }
    };
  },
  methods: {
    getMapData(pop) {
      const stateObj = data.find(el => {
        return el[1] === pop;
      });

      const dataItems = [
        {
          title: "Total Population",
          value: this.formatNumber(stateObj[2].total_population)
        },
        {
          title: "Population 41-50",
          value: this.formatNumber(stateObj[2].population_41_50)
        },
        {
          title: "Population 51-60",
          value: this.formatNumber(stateObj[2].population_51_60)
        },
        {
          title: "Population 61-70",
          value: this.formatNumber(stateObj[2].population_61_70)
        },
        {
          title: "Population 71-80",
          value: this.formatNumber(stateObj[2].population_71_80)
        },
        {
          title: "Pop. Receiving SS",
          value: this.formatNumber(stateObj[2].population_receiving_ss)
        },
        {
          title: "Avg. SS Benefit",
          value: stateObj[2].avg_monthly_benefit
        }
      ];

      const stateDataHtml = `<table>
        <tbody>
          <tr>
            <td>${dataItems[0].title}</td>
            <td class="text-right">${dataItems[0].value}</td>
          </tr>
           <tr>
            <td>${dataItems[1].title}</td>
            <td class="text-right">${dataItems[1].value}</td>
          </tr>
           <tr>
            <td>${dataItems[2].title}</td>
            <td class="text-right">${dataItems[2].value}</td>
          </tr>
           <tr>
            <td>${dataItems[3].title}</td>
            <td class="text-right">${dataItems[3].value}</td>
          </tr>
           <tr>
            <td>${dataItems[4].title}</td>
            <td class="text-right">${dataItems[4].value}</td>
          </tr>
           <tr>
            <td>${dataItems[5].title}</td>
            <td class="text-right">${dataItems[5].value}</td>
          </tr>
          <tr>
            <td>${dataItems[6].title}</td>
            <td class="text-right">${dataItems[6].value}/mo</td>
          </tr>
          </tbody>
      </table>`;
      return stateDataHtml;
    },
    formatNumber(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0
      });
      return formatter.format(value);
    }
  }
};
</script>

<style lang="scss" scoped></style>
