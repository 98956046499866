<template>
  <div>
    <div v-if="isLoading" class="pa-16 d-flex align-center justify-center">
      <v-progress-circular
        v-if="isLoading"
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>

    <v-alert v-if="showAlert" dense outlined type="success" class="mt-5">
      API settings have been saved!
    </v-alert>

    <template v-if="!isLoading">
      <p class="mt-6 mb-8">
        API Access Tokens give you PlanGap Insights API access. You can create
        multiple tokens to control permissions for environments (e.g.
        Development, Production). Keep API Access keys secure!
      </p>

      <v-text-field
        value="71ddc4b9-1703-41ca-9c99-da301e767638"
        label="Public API Key"
        append-icon="mdi-content-copy"
        color="grey"
        outlined
        readonly
      ></v-text-field>
      <v-text-field
        value="71ddc4b9-1703-41ca-9c99-da301e767638"
        type="password"
        label="Secret Key"
        outlined
        disabled
        class="mb-0 pgi-api-field"
        hint="Generated 2/15/2021 by: rich@plangap.com"
      ></v-text-field>
      <p class="mb-8 text-caption d-flex justify-space-between">
        <span>Generated 2/15/2021 by: rich@plangap.com</span>
        <span class="error--text">Revoke</span>
      </p>
      <v-divider></v-divider>
      <div class="mt-5 d-flex justify-space-between">
        <v-btn class="mr-4" color="background" dark>Add Key</v-btn>
        <a href="#" class="background--text">View API Documentation</a>
      </div>
      <!-- <v-list>
        <v-list-item>
          <v-list-item-content>
            View PlanGap API Documentation
          </v-list-item-content>
        </v-list-item>
      </v-list> -->
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showAlert: false,
      isLoading: false
    };
  },
  methods: {
    handleSaveSubscriptions() {
      this.isLoading = true;

      setTimeout(() => {
        this.isLoading = false;
        this.showAlert = true;
      }, 1500);
    }
  }
};
</script>

<style lang="scss">
/* .v-text-field__details {
  display: none;
} */
</style>
