<template>
  <highcharts :options="getChartOptions"></highcharts>
</template>

<script>
export default {
  name: "SliderChart",
  components: {},
  data() {
    return {
      chartOptions: {
        chart: {
          type: "area",
          spacing: [0, -10, 0, -10],
          backgroundColor: "transparent",
          height: 350 //(9 / 16) * 100 + "%" // 16:9 ratio
        },
        title: {
          text: undefined
        },
        credits: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        series: [
          {
            data: [],
            states: {
              hover: {
                enabled: false
              }
            },
            color: "#1796C4",
            marker: {
              enabled: false
            }
          }
        ],
        yAxis: {
          visible: false
          // gridLineWidth: 0,
          // labels: {
          //   enabled: false
          // },
          // title: {
          //   enabled: false
          // }
        },
        xAxis: {
          visible: false
          // gridLineWidth: 0,
          // labels: {
          //   enabled: false
          // },
          // title: {
          //   enabled: false
          // }
        },
        legend: {
          enabled: false
        }
      }
    };
  },
  computed: {
    getChartOptions() {
      let data = [];
      let visits = 10;
      for (let i = 1; i < 80; i++) {
        visits += Math.round(
          (Math.random() < 0.5 ? 1 : -1) * Math.random() * 20
        );
        data.push({
          x: new Date(2018, 0, i),
          // name: "name" + i,
          y: Math.abs(visits)
        });
      }

      let _chartOptions = this.chartOptions;

      _chartOptions.series[0].data = data;
      // console.log("chartoptons", _chartOptions);
      return _chartOptions;
    }
  }
};
</script>

<style lang="scss" scoped></style>
