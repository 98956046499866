<template>
  <BaseCardWithHeader toolbarTitle="Index Trend" toolbarSubTitle="">
    <template v-slot:extra>
      <!-- <v-container class="mt-2"> -->
      <v-row align="center" justify="center" class="mt-2">
        <v-col class="d-flex" cols="12" sm="6">
          <v-select
            v-model="selectedYear"
            :items="allBirthYears"
            :menu-props="{ maxHeight: '400' }"
            @change="getBirthYearData()"
            label="Select Birth Year"
            dense
          ></v-select>
        </v-col>
        <v-col class="d-flex" cols="12" sm="6">
          <v-select
            v-model="selectedIndex"
            :items="indexOptions"
            label="Select Index"
            dense
          ></v-select>
        </v-col>
      </v-row>
      <!-- </v-container> -->
    </template>

    <highcharts
      :options="getChartOptions"
      :updateArgs="[true, false, true]"
    ></highcharts>
  </BaseCardWithHeader>
</template>

<script>
import config from "@/config";
import BaseCardWithHeader from "@/components/BaseCardWithHeader";

export default {
  name: "MockChart",
  components: {
    BaseCardWithHeader,
  },
  data() {
    let that = this;
    return {
      chartData: [],
      chartOpts: {
        chart: {
          type: "area",
          height: 500,
          //spacing: [0, -10, 0, -10],
          backgroundColor: "transparent",
        },
        title: {
          text: undefined,
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          formatter: function() {
            return `${that.formatCurrency(this.y)}`;
          },
          shared: false,
          useHTML: true,
        },
        plotOptions: {
          series: {
            fillOpacity: 0.1,
            states: {
              hover: {
                enabled: false,
              },
            },
            events: {
              legendItemClick: function() {
                return false;
              },
            },
          },
        },
        series: [
          {
            name: "",
            data: [],
            states: {
              hover: {
                enabled: false,
              },
            },
            borderWidth: 0,
            color: "#50cbff",
            marker: {
              enabled: true,
            },
          },
          // {
          //   name: this.yearRange[1],
          //   data: [],
          //   states: {
          //     hover: {
          //       enabled: false
          //     }
          //   },
          //   borderWidth: 0,
          //   color: "#1796C4",
          //   marker: {
          //     enabled: false
          //   }
          // }
        ],

        yAxis: {
          gridLineColor: "#5c7196",
          title: {
            text: "Index",
            style: {
              color: "#ffffff",
            },
          },
          labels: {
            style: {
              color: "#ffffff",
            },
          },
          crosshair: {
            snap: false,
            dashStyle: "dot",
            color: "#94B0E0",
          },
          //   visible: false
          // gridLineWidth: 0,
          // labels: {
          //   enabled: false
          // },
          // title: {
          //   enabled: false
          // }
        },
        xAxis: {
          title: {
            text: "Year",
            style: {
              color: "#ffffff",
            },
          },
          labels: {
            style: {
              color: "#ffffff",
            },
          },
          //categories: [this.yearRange[0], this.yearRange[1]],
          crosshair: {
            snap: true,
            dashStyle: "dot",
            color: "#94B0E0",
          },
          //   visible: false
          gridLineWidth: 1,
          gridLineColor: "#5c7196",
          // labels: {
          //   enabled: false
          // },
          // title: {
          //   enabled: false
          // }
        },
        legend: {
          enabled: true,
          itemStyle: {
            color: "#ffffff",
            cursor: "pointer",
            fontSize: "12px",
            fontWeight: "bold",
            textOverflow: "ellipsis",
          },
        },
      },
      selectedYear: 1930,
      allBirthYears: this.getAllYearsAsArray(),
      indexOptions: ["Annuity"],
      selectedIndex: "Annuity",
    };
  },
  mounted() {
    this.getBirthYearData();
    // this.chartOptions.series[0].data = this.getBirthYearData(_year);
  },
  beforeDestroy() {},

  computed: {
    getChartOptions() {
      // let options = this.chartOptions;
      // options.series[0].data = this.chartData;
      // console.log("OPTIONS HAVE CHNAGED", options);
      return this.chartOpts;
    },
  },
  watch: {
    chartData() {
      let options = this.chartOpts;
      // options.series[0].data = this.chartData;
      // console.log("OPTIONS HAVE CHANGED", options);
      options.series[0].data = this.chartData;
      options.series[0].name = `Birth Year: ${this.selectedYear}`;
      this.chartOpts = options;
    },
  },
  methods: {
    formatCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      });
      return formatter.format(value);
    },
    getAllYearsAsArray() {
      const range = (start, stop, step) =>
        Array.from(
          { length: (stop - start) / step + 1 },
          (_, i) => start + i * step
        );
      return range(1930, 2004, 1);
    },
    async getBirthYearData() {
      let newData = [];
      const _selectedYear = this.selectedYear;
      const currYear = new Date().getFullYear();
      // eslint-disable-next-line
      const fetchPath = `${config.SSBI_API_URL}get_summary?yob=${_selectedYear}&data_year=${currYear}`;
      await fetch(fetchPath)
        .then((r) => r.json())
        .then(
          (json) => {
            // console.log("JSON ", json);
            // let dataObj = {
            //   indexYear: null,
            //   indexValue: null
            // };

            // let dataObj = {
            //   x: null,
            //   y: null
            // };

            // For year summary data coming in {year}, find the index for the year give (eg: 2019)
            // add that to an array for data
            Object.entries(json.data).map(([k, v]) => {
              // dataObj.x = [k, v][1].year;
              // dataObj.y = [k, v][1].index;
              let dataEntry = [[k, v][1].year, [k, v][1].index];
              newData.push(dataEntry);
            });
          },
          (response) => {
            console.log("Error loading json:", response);
          }
        );
      //this.$set(this.chartData, );
      this.chartData = newData;
      // console.log("new data", this.chartData);

      // let _newData = this.chartData;
      // _newData = newData;
      // console.log("OPTIONS HAVE CHNAGED", _newData);
      // return _newData;
    },
  },
};
</script>

<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 500px;
}
</style>
