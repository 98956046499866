<template>
  <v-container fluid class="mt-4 py-6">
    <v-row class="mb-6">
      <v-col cols="12" sm="6" lg="3">
        <div @click="handleShowSubscriptionDialog(true)">
          <TopCard
            mainColor="yellow"
            adjustColor="darken2"
            iconLeft="mdi-at"
            iconRight="mdi-arrow-right"
            title="Subscribe & Manage Alerts"
          >
            Subscribe & Manage Alerts
          </TopCard>
          <v-dialog
            v-if="isSubscribeDialogOpen"
            persistent
            overlay-opacity="0.8"
            overlay-color="#2C4779"
            v-model="showSubscriptionDialog"
            transition="scroll-y-transition"
            max-width="600"
            :scrollable="true"
          >
            <v-card light>
              <v-card-title class="pa-0">
                <v-toolbar color="dashCard" dark>
                  <v-btn icon>
                    <v-icon>mdi-at</v-icon>
                  </v-btn>
                  <v-toolbar-title>Subscribe & Manage Alerts</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    @click="
                      showSubscriptionDialog = false;
                      isSubscribeDialogOpen = false;
                    "
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar></v-card-title
              >

              <v-card-text class="">
                <SubscriptionsForm
                  @showSubscriptionDialog="handleShowSubscriptionDialog"
                />
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <TopCard
          mainColor="teal"
          adjustColor="accent2"
          iconLeft="mdi-alert-circle-outline"
          iconRight="mdi-open-in-new"
          target="_blank"
          link="https://www.socialsecuritybenefitindex.org/202"
          :title="
            `${new Date().getFullYear()} Index Calculations Now Available!`
          "
        >
          {{ `${new Date().getFullYear()} Index Calculations Now Available!` }}
        </TopCard>
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <div @click="showApiAccessDialog = true">
          <TopCard
            mainColor="deepPurple"
            adjustColor="accent1"
            iconLeft="mdi-code-tags"
            iconRight="mdi-arrow-right"
            title="Developer API Access & Documentation"
          >
            Developer API Access & Documentation
          </TopCard>

          <v-dialog
            overlay-opacity="0.8"
            overlay-color="#2C4779"
            v-model="showApiAccessDialog"
            transition="scroll-y-transition"
            max-width="600"
            :scrollable="true"
          >
            <v-card light>
              <v-card-title class="pa-0">
                <v-toolbar color="dashCard" dark>
                  <v-btn icon>
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                  <v-toolbar-title
                    >Developer API Access & Documentation</v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                  <v-btn icon @click="showApiAccessDialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar></v-card-title
              >

              <v-card-text class="">
                <ApiAccessForm />
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </v-col>
      <v-col cols="12" sm="6" lg="3"
        ><TopCard
          mainColor="blue"
          adjustColor="accent1"
          iconLeft="mdi-application"
          iconRight="mdi-open-in-new"
          target="_blank"
          link="https://claimsdemo.plangap.com/?c=north-american"
          title="PlanGap Claims Process Demo"
        >
          PlanGap Claims Process Demo
        </TopCard></v-col
      >
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <ClaimsTable />
      </v-col>
      <v-col cols="12" md="6">
        <v-container pa-0 fill-height align-start>
          <v-row>
            <v-col>
              <Simulations />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <BaseCardWithHeader
                toolbarTitle="Access SSBI Data"
                :hasCollectionButton="false"
                alternateButton="mdi-open-in-new"
                alternateButtonLink="https://www.socialsecuritybenefitindex.org/202"
              >
                <a
                  href="https://www.socialsecuritybenefitindex.org/202"
                  target="_blank"
                  class="py-4 d-flex align-center"
                  style="background: #F8F8F8"
                >
                  <v-img
                    max-width="192"
                    src="@/assets/images/ssbi-logo.png"
                    style="margin: 0 auto"
                  ></v-img>
                </a>
              </BaseCardWithHeader>
            </v-col>
            <v-col cols="12" md="6">
              <Reports />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <!-- Columns start at 50% wide on mobile and bump up to 33.3% wide on desktop -->
    <v-row>
      <v-col cols="12" md="8">
        <MockChart />
      </v-col>
      <v-col cols="12" md="4">
        <LatestNews />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <BaseCardWithHeader
          :flat="true"
          :hasCollectionButton="false"
          cardColor="transparent"
          toolbarTitle="State Level Information"
          toolbarSubTitle="Filter, download, and create collections based on state specific data."
        >
          <MockMap />
        </BaseCardWithHeader>
      </v-col>
    </v-row>
    <!-- <Collection /> -->
  </v-container>
</template>

<script>
// @ is an alias to /src\
import ApiAccessForm from "@/components/ApiAccessForm";
import BaseCardWithHeader from "@/components/BaseCardWithHeader";
import ClaimsTable from "@/components/ClaimsTable";
// import Collection from "@/components/Collection";
import LatestNews from "@/components/LatestNews";
import MockChart from "@/components/MockChart";
import MockMap from "@/components/MockMap";
import Reports from "@/components/Reports";
import Simulations from "@/components/Simulations";
import SubscriptionsForm from "@/components/SubscriptionsForm";
import TopCard from "@/components/TopCard";

export default {
  name: "Home",
  components: {
    ApiAccessForm,
    BaseCardWithHeader,
    ClaimsTable,
    // Collection,
    LatestNews,
    MockChart,
    MockMap,
    Reports,
    Simulations,
    SubscriptionsForm,
    TopCard,
  },
  data() {
    return {
      isSubscribeDialogOpen: false, // so data is reset and dialog is repopulated in case user doesn't save changes
      showSubscriptionDialog: false,
      showApiAccessDialog: false,
    };
  },
  methods: {
    handleShowSubscriptionDialog(val) {
      if (val) {
        this.isSubscribeDialogOpen = true;
      } else {
        this.isSubscribeDialogOpen = false;
      }
      this.showSubscriptionDialog = val;
    },
  },
};
</script>
