<template>
  <BaseCardWithHeader
    cardColor="background"
    toolbarTitle="Simulations"
    toolbarSubTitle="See how various factors such as policy changes could affect claim
            outcomes."
  >
    <v-carousel
      :continuous="false"
      :cycle="false"
      :interval="4000"
      :show-arrows="false"
      hide-delimiter-background
      delimiter-icon="mdi-minus"
      height="350"
    >
      <v-carousel-item v-for="(sim, i) in simulations" :key="i">
        <v-sheet height="100%" class="transparent">
          <v-row class="fill-height" align="center">
            <v-col>
              <v-list-item four-line>
                <div class="pgi-bg-chart">
                  <SliderChart />
                </div>
                <v-list-item-content class="pgi-item-content">
                  <!-- <v-list-item-subtitle>
                    {{ sim.date }}
                  </v-list-item-subtitle> -->
                  <v-list-item-title class="my-2 text-h5"
                    ><strong>Scenario: </strong
                    >{{ sim.scenario }}</v-list-item-title
                  >
                  <v-list-item-subtitle class="mb-2 text-subtitle-1">
                    <span><strong>Basis:</strong> {{ sim.basis }}</span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="mb-2 text-subtitle-1">
                    <span><strong>Type:</strong> {{ sim.type }}</span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="mb-2 text-subtitle-1">
                    <span><strong>Timeline:</strong> {{ sim.timeline }}</span>
                  </v-list-item-subtitle>
                  <v-alert dense outlined class="mt-6">
                    <v-row align="center">
                      <v-col class="grow">
                        <v-icon left>mdi-creation</v-icon>
                        View Simulation and Criteria
                      </v-col>
                      <v-col class="shrink">
                        <v-btn
                          @click="handleSimulate(sim.id)"
                          color="primary darken-2"
                          >Simulate</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </BaseCardWithHeader>
</template>

<script>
import { mapGetters } from "vuex";
import BaseCardWithHeader from "@/components/BaseCardWithHeader";
import SliderChart from "@/components/SliderChart";

export default {
  components: {
    BaseCardWithHeader,
    SliderChart
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    ...mapGetters("simulationsState", ["simulations"])
  },
  methods: {
    handleSimulate(id) {
      const to = `/simulation/${id}`;
      this.$router.push(to);
    }
  }
};
</script>
<style lang="scss" scoped>
.v-list-item {
  position: static;
}
.pgi-bg-chart {
  opacity: 0.15;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.pgi-item-content {
  max-width: 80%;
  margin: 0 auto;
}
</style>
