<template>
  <BaseCardWithHeader toolbarTitle="Policy News" toolbarSubTitle="">
    <v-timeline align-top dense :style="{ background: '#19274c' }">
      <v-virtual-scroll :items="newsItems" height="475" item-height="90"
        ><template v-slot:default="{ item, index }">
          <v-timeline-item :key="index" :color="item.dotColor" small>
            <v-row class="pt-1">
              <v-col>
                <div class="caption">{{ item.date }}</div>
                <a
                  :href="item.url"
                  rel="noopener noreferrer"
                  target="_blank"
                  class="text-body-2"
                >
                  {{ item.text }}
                </a>
              </v-col>
            </v-row>
          </v-timeline-item>
        </template>
      </v-virtual-scroll>
    </v-timeline>
  </BaseCardWithHeader>
</template>

<script>
import BaseCardWithHeader from "@/components/BaseCardWithHeader";

export default {
  components: {
    BaseCardWithHeader,
  },
  data() {
    return {
      newsItems: [
        {
          text: "Summary of 2022 Annual Report",
          date: "June 2 2022",
          source: "SSA.gov",
          url:
            "https://plangapinsights.news/summary-of-the-2022-annual-report/",
          dotColor: "secondary",
        },
        {
          text: "Summary of 2021 Annual Report",
          date: "August 31, 2021",
          source: "SSA.gov",
          url:
            "https://plangapinsights.news/summary-of-the-2021-annual-reports/",
        },
        {
          text: "Summary of 2020 Annual Report",
          date: "February 18, 2021",
          source: "SSA.gov",
          url:
            "https://plangapinsights.news/summary-of-the-2020-annual-reports/",
        },
        {
          text: "How Will COVID-19 Affect the Social Security Trust Funds?",
          date: "October 22, 2020",
          source: "PlanGap Insights - News",
          url:
            "https://plangapinsights.news/how-will-covid-19-affect-the-social-security-trust-funds",
        },
        {
          text:
            "Congressional Budget Office’s 2020 Long-Term Projections for Social Security",
          date: "September 30, 2020",
          source: "PlanGap Insights - News",
          url:
            "https://plangapinsights.news/congressional-budget-offices-2020-long-term-projections-for-social-security/",
        },
        {
          text:
            "Social Security: What Would Happen If the Trust Funds Ran Out?",
          date: "July 29, 2020",
          source: "PlanGap Insights - News",
          url:
            "https://plangapinsights.news/social-security-what-would-happen-if-the-trust-funds-ran-out/",
        },
        {
          text: "Projecting COVID’s Fiscal Impact",
          date: "June 29, 2020",
          source: "PlanGap Insights - News",
          url: "https://plangapinsights.news/projecting-covids-fiscal-impact/",
        },
        {
          text:
            "The Impact of the Coronavirus Pandemic on Social Security’s Finances",
          date: "May 28, 2020",
          source: "PlanGap Insights - News",
          url:
            "https://plangapinsights.news/the-impact-of-the-coronavirus-pandemic-on-social-securitys-finances/",
        },
        {
          text: "What the 2020 Trustees’ Report Shows About Social Security",
          date: "May 13, 2020",
          source: "PlanGap Insights - News",
          url:
            "https://plangapinsights.news/what-the-2020-trustees-report-shows-about-social-security/",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.v-virtual-scroll {
  overflow-x: hidden;
}
</style>
