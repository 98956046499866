<template>
  <div>
    <div v-if="isLoading" class="pa-16 d-flex align-center justify-center">
      <v-progress-circular
        v-if="isLoading"
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>

    <v-alert
      v-if="showAlert"
      dense
      outlined
      :type="updateSuccessful ? 'success' : 'danger'"
      class="mt-5"
    >
      {{ alertMessage }}
    </v-alert>

    <template v-if="!isLoading">
      <v-subheader
        >Select the topics you would like to receive in your email.</v-subheader
      >
      <v-list>
        <v-list-item-group
          multiple
          active-class=""
          v-model.lazy="selectedInterests"
        >
          <template v-for="interest in mailchimpListInterests">
            <v-list-item :key="interest.id" :value="interest">
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox
                    :input-value="active"
                    color="background"
                  ></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ interest.name }}</v-list-item-title>
                  <!-- <v-list-item-subtitle>{{
                  interest.description
                }}</v-list-item-subtitle> -->
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>

      <div class="mt-5 text-center">
        <v-btn
          x-large
          color="primary darken-2"
          :disabled="!interestsChanged"
          @click="handleSaveSubscriptions"
        >
          Update Subscriptions
        </v-btn>

        <v-btn x-large color="" class="ml-5" @click="handleClose">
          Cancel
        </v-btn>
        <p class="mt-4 mb-0">
          You can update your email address in your account settings.
        </p>
      </div>
    </template>
  </div>
</template>

<script>
// import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import {
  interestsFromInterestCategoryMailchimp,
  updateListMemberMailchimp,
  getMailchimpMember
} from "@/services/mailchimpServices.js";

export default {
  data() {
    return {
      selectedInterests: [],
      interestsChanged: null,
      alertMessage: " Subscriptions settings have been saved!",
      showAlert: false,
      updateSuccessful: null,
      isLoading: false,
      zzzzsubscriptionInterests: [
        // {
        //   name: "PlanGap Advisory System",
        //   description: "Notify me about changes to the PlanGap Advisory System."
        // },
        // {
        //   name: "State of Social Security updates",
        //   description:
        //     "Notify me when there are changes and updates to the state of Social Security."
        // },
        // {
        //   name: "Curated news about Social Security",
        //   description:
        //     "Send me PlanGap curated news and information relevant to Social Security."
        // },
        // {
        //   name: "PlanGap Insights Tools and Features",
        //   description:
        //     "Notify me when PlanGap releases new or updated tools and features."
        // }
      ]
    };
  },
  computed: {
    ...mapGetters({
      currentUser: ["auth/currentUser"],
      mailchimpListInterests: ["app/mailchimpListInterests"],
      userMailchimpInterests: ["userPreferences/userMailchimpInterests"]
    })
  },
  watch: {
    selectedInterests: {
      handler: function(newVal, oldVal) {
        // show update button if changed
        if (newVal !== oldVal) {
          this.interestsChanged = true;
        }
      },
      deep: true
    }
  },
  mounted() {
    this.setupInterests();
  },
  methods: {
    ...mapActions({
      setMailchimpListInterests: "app/setMailchimpListInterests",
      setUserMailchimpInterests: "userPreferences/setUserMailchimpInterests"
    }),
    async setupInterests() {
      this.isLoading = true;
      // If no interests are set in state, call to MC for interests
      if (this.mailchimpListInterests.length === 0) {
        await this.getListInterests();
      }
      // We should have list of interests from state or from fetching
      // Now get MC user and apply the interests selections that are set
      // for them in MC to select them in our form
      await this.getUsersListInterests();

      // Now we need to assign the user's interets to the selected options
      this.isLoading = false;
    },
    // Make call to MC to get all of the list's interests to generate checkboxes in form
    async getListInterests() {
      try {
        const interestsObj = await interestsFromInterestCategoryMailchimp();

        this.setMailchimpListInterests(interestsObj.interests);
      } catch (error) {
        console.log("getListInterests error: ", error);
      }
    },
    async getUsersListInterests() {
      try {
        const mailchimpMember = await getMailchimpMember({
          email: this.currentUser.email
        });

        this.setUserMailchimpInterests(mailchimpMember.interests);

        // Check which interests the user has chosen and build array
        const that = this;
        const userInterests = [];
        Object.entries(mailchimpMember.interests).map(([key, obj]) => {
          // If the interest is true - the user is subscribed to it in MC
          // So add it to array
          if (obj === true) {
            userInterests.push({ id: key });
          }
        });

        // Check user subscribed interests against the list of interests
        // and them to the selected Interests to select them in the UI form
        this.mailchimpListInterests.some(interest => {
          if (userInterests.some(userInt => userInt["id"] === interest.id)) {
            that.selectedInterests.push(interest);
          }
        });
      } catch (error) {
        this.alertMessage =
          "Sorry, something went wrong. Please refresh and try again.";
        console.log("getUsersListInterests error", error);
      }
    },
    // Make call to MC to set interests based on user selections
    // Update Mailchimp member with interests object of selections
    async handleSaveSubscriptions() {
      this.isLoading = true;
      this.showAlert = false;

      const subscriberEmail = this.currentUser.email;
      const that = this;
      let updatedInterests = {};

      this.mailchimpListInterests.some(interest => {
        if (that.selectedInterests.includes(interest)) {
          updatedInterests[interest.id] = true;
        } else {
          updatedInterests[interest.id] = false;
        }
      });

      const mcMemberUpdateData = { interests: { ...updatedInterests } };
      const updateData = { email: subscriberEmail, data: mcMemberUpdateData };

      try {
        await updateListMemberMailchimp(updateData);

        this.updateSuccessful = true;
      } catch (error) {
        console.log("Save Subscription Settings Error: ", error);
        this.updateSuccessful = false;
        this.alertMessage =
          "Sorry, something went wrong. Please refresh and try again.";
      }

      setTimeout(() => {
        this.showAlert = true;
        this.isLoading = false;
        this.interestsChanged = false;
      }, 1500);
    },
    handleClose() {
      this.$emit("showSubscriptionDialog", false);
    }
  }
};
</script>

<style></style>
