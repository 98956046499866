<template>
  <div class="pa-1 pgi-top-card">
    <div class="pgi-top-card__glow" :style="{ background: glowGradient }"></div>
    <BaseCardWithHeader :hasCollectionButton="false">
      <a
        :href="link"
        :target="target"
        class="d-flex align-center text-decoration-none pgi-top-card__content"
        :style="{ color: textColor }"
      >
        <div class="d-flex align-center text-truncate">
          <v-icon left class="mr-2" :color="textColor">
            {{ iconLeft }}
          </v-icon>
          <slot />
        </div>
        <v-spacer />
        <v-icon :color="textColor">
          {{ iconRight }}
        </v-icon>
      </a>
    </BaseCardWithHeader>
  </div>
  <!-- <MiniGauge /> -->
</template>

<script>
import BaseCardWithHeader from "@/components/BaseCardWithHeader";
// import MiniGauge from "@/components/MiniGauge";
import colors from "vuetify/lib/util/colors";
export default {
  name: "TopLastCard",
  props: {
    mainColor: String,
    adjustColor: String,
    iconLeft: String,
    iconRight: String,
    link: String,
    target: String,
  },
  components: {
    BaseCardWithHeader,
    // MiniGauge
  },
  computed: {
    glowGradient() {
      // const _mainColor: this.mainColor;
      // const _adjustColor: this.adjustColor;
      const gradient = `linear-gradient(to right, ${
        colors[this.mainColor][this.adjustColor]
      } 1%, transparent)`;
      // console.log("GRADIENT", gradient);
      return gradient;
    },
    colorMerge() {
      return `${this.mainColor} ${this.adjustColor}`;
    },
    textColor() {
      //map-get($deep-purple, accent-1);

      const _textColor = colors[this.mainColor][this.adjustColor];

      return _textColor;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.pgi-top-card {
  position: relative;
  cursor: pointer;
  font-size: 0.875rem;

  &__content {
    position: relative;
    z-index: 2;
    padding: 0.25rem;
  }

  &__glow {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    transition: all 0.15s ease-in-out;
    opacity: 0.15;
  }

  &:hover & {
    &__glow {
      opacity: 0.2;
    }
  }
}
</style>
